<div
  [class]="'th-wrapper--align-' + alignment"
  class="th-wrapper"
>
  <div class="th-wrapper__content">
    <ng-content></ng-content>
  </div>

  @if (tooltip) {
    <div class="th-wrapper__tooltip">
      <i
        [ccTooltip]="tooltip"
        class="icon-info-fill"
      ></i>
    </div>
  }

  @if (sortable) {
    <div class="th-wrapper__sort">
      <i
        [class]="sortIconClass"
        class="icon-arrow-top-line"
      ></i>
    </div>
  }
</div>
