import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, output } from '@angular/core';

import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { Alignment, SortDirection } from '@design/table/table';

@Component({
  selector: 'th[ccTh]',
  templateUrl: './th.component.html',
  styleUrls: ['./th.component.scss'],
  standalone: true,
  host: {
    '[style.width]': 'width',
    '[style.min-width]': 'minWidth',
  },
  imports: [TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThComponent {
  @Input('ccSortable')
  sortable = false;

  @Input('ccSortDirection')
  sortDirection: SortDirection = null;

  @Input('ccWidth')
  width: string = 'auto';

  @Input('ccMinWidth')
  minWidth: string = 'auto';

  @Input('ccHorizontalAlignment')
  alignment: Alignment = 'start';

  @Input('ccThTooltip')
  tooltip: string | null = null;

  sortChange = output<SortDirection>({ alias: 'ccSortChange' });

  get sortIconClass(): string {
    switch (this.sortDirection) {
      case 'asc': {
        return 'cc-th--sort-asc';
      }
      case 'desc': {
        return 'cc-th--sort-desc';
      }
      default: {
        return 'cc-th--sort-none';
      }
    }
  }

  @HostBinding('class')
  get hostClass(): string {
    const classes: string[] = [];

    if (this.sortable) {
      classes.push('cc-th--sortable');
    }

    return classes.join(' ');
  }

  @HostListener('click')
  toggleSort() {
    if (!this.sortable) return;

    switch (this.sortDirection) {
      case 'desc': {
        this.sortDirection = 'asc';
        break;
      }
      default: {
        this.sortDirection = 'desc';
        break;
      }
    }

    this.sortChange.emit(this.sortDirection);
  }
}
